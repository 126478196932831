import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";

import Text from "../Text";
import {
  Align,
  AvailableTag,
  BreakpointAlign,
  BreakpointWhiteSpace,
  Color,
  Whitespace,
} from "../Text.map";

import { bodySizeMap, TBodySize } from "./TextBody.map";

type TTextBodyWeight = "default" | "bold";

export interface IBodyProps extends WithDataAttr {
  id?: string;
  tag?: AvailableTag;
  size?: TBodySize
  align?: Align | BreakpointAlign;
  color?: Color;
  className?: string;
  whitespace?: Whitespace | BreakpointWhiteSpace;
  children: ReactNode;
  weight?: TTextBodyWeight;
}

const TextBody: FC<IBodyProps> = ({
  id,
  size = 1,
  align,
  color,
  tag = "p",
  "data-testid": testId,
  className,
  whitespace,
  weight,
  children,
}) => (
  <Text
    id={ id }
    size={ bodySizeMap[size] }
    align={ align }
    tag={ tag }
    color={ color }
    data-testid={ testId }
    className={ className }
    whitespace={ whitespace }
    display={ weight }
  >
    { children }
  </Text>
);

export default memo(TextBody);
