import {
  FC,
  memo,
  ComponentType,
  ReactNode,
} from "react";
import Link from "next/link";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Tag, { ITagProps } from "apps/website/components/base/Tag/Tag";
import {
  colorClassMap,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Video, { IVideoPath } from "apps/website/components/base/Video/Video";
import { WithTestID } from "apps/website/types";

import TextBody from "../../base/Text/TextBody/TextBody";

import {
  AspectRatio,
  aspectRatioClassMap,
  cardHoverClassMap,
  Hover,
} from "./Card.map";

type Align = "default" | "center";
type Divider = "default" | "small" | "none";
export type CardCtaAction = "link" | "modal";
type TTagPosition = "default" | "hugBorderLeft";

export interface ICard extends WithTestID {
  title: string;
  description?: string;
  image?: IImageProps;
  imageRatio?: AspectRatio;
  hoverMedia?: IImageProps | IVideoPath;
  hover?: Hover;
  Footer: ComponentType;
  theme?: Theme;
  component?: string;
  tag?: ITagProps;
  tagPosition?: TTagPosition;
  align?: Align;
  divider?: Divider;
  link?: string;
  onClick?: () => void;
  className?: string;
  ctaAction?: CardCtaAction;
}

const CardLink: FC<{ children: ReactNode, tabbable?: boolean, link?: string, ctaAction?: CardCtaAction }> = ({ children, tabbable = false, link, ctaAction = "modal" }) => (
  <>
    {
      (link && ctaAction === "link") ? (
        <Link
          href={link}
          className="hover:cursor-pointer"
          tabIndex={!tabbable ? -1 : 0}>{ children }</Link>
      ) : (
        <>
          { children }
        </>
      )
    }
  </>
);

const Card: FC<ICard> = ({
  "data-testid": testId,
  title,
  description,
  image,
  imageRatio = "default",
  hoverMedia,
  hover = "default",
  Footer,
  tag = undefined,
  tagPosition = "default",
  ctaAction = "modal",
  component = "Card",
  theme = "dark",
  align = "default",
  divider = "default",
  link,
  onClick,
  className,
}) => {

  const computedClasses = `${className} ${cardHoverClassMap[hover]} ${themeRootClassMap[theme]}`;

  return (
    <div data-theme={theme} data-component={component} className="h-full">
      <Grid
        data-testid={testId}
        tag="article"
        gap="none"
        className={`h-full ${computedClasses}`}
      >
        <Column className="group" onClick={onClick}>
          <CardLink link={link} ctaAction={ctaAction}>
            <div className="relative bg-light-grey">
              { image?.src && (
                <div className={aspectRatioClassMap[imageRatio]}>
                  <Image image={image} alt={title} cover />
                </div>
              ) }
              <div className="absolute h-full w-full top-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 hidden xl:block">
                { hoverMedia && (
                  <>
                    { typeof hoverMedia === "string" ? (

                      <Video video={hoverMedia} poster={image?.src ?? ""} cover canPause={false}/>
                    ) : (
                      <Image image={hoverMedia} alt={title} cover data-testid="CardImage" />
                    ) }
                  </>
                ) }
              </div>
              { tag && <Tag
                theme={tag.theme}
                icon={tag.icon}
                className={ `absolute z-10 top-1 lg:top-4 ${tagPosition === "hugBorderLeft" ? "left-0" : "left-1 lg:left-4"}`}
              >{ tag.text }</Tag> }
            </div>
          </CardLink>
          <div className="h-full flex flex-col justify-between">
            <div className="px-4">
              <Spacer size="md" />
              <CardLink link={link} ctaAction={ctaAction}>
                { /** No matching Card title size */ }
                <Text
                  tag="h3"
                  display="title"
                  data-testid="CardTitle"
                  size={legacySizeCollectionMap.bodyLg}
                  align={align}
                  onClick={onClick}>{ title }</Text>
              </CardLink>
              <Spacer size="md" />
              { description && (
                <>
                  <TextBody size={ 2 } align={ align } data-testid="CardBody">{ description }</TextBody>
                  <Spacer size="md" />
                </>
              ) }
            </div>
            <div className="px-4">
              { divider !== "none" && (
                <>
                  { divider === "default" && (
                    <>
                      <Spacer size="md" />
                      <hr className={colorClassMap.default}/>
                      <Spacer size="md" />
                    </>
                  ) }
                  { divider === "small" && (
                    <div className="flex justify-center items-center">
                      <div className="w-[25%]">
                        <hr className={colorClassMap.default}/>
                        <Spacer size="md" />
                      </div>
                    </div>
                  ) }
                </>
              ) }
              <div data-footer>
                <Grid gap="none">
                  <Column direction="row" justify={align === "default" ? "between" : "center"} align="center">
                    <Footer />
                  </Column>
                </Grid>
                <Spacer size="md" />
              </div>
            </div>
          </div>
        </Column>
      </Grid>
    </div>
  );
};

export default memo(Card);
