import { BreakpointSize, sizeCollectionMap } from "../Text.map";

export const titleSizeMap: Record<number | "hero", BreakpointSize> = {
  hero: sizeCollectionMap[1],
  1: sizeCollectionMap[2],
  2: sizeCollectionMap[3],
  3: sizeCollectionMap[5],
};

export type TTitleSize = keyof typeof titleSizeMap;
