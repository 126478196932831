import { BreakpointSize, sizeCollectionMap } from "../Text.map";

export const bodySizeMap: Record<number, BreakpointSize> = {
  1: sizeCollectionMap[4],
  2: sizeCollectionMap[7],
  3: sizeCollectionMap[8],
  4: sizeCollectionMap[10],
};

export type TBodySize = keyof typeof bodySizeMap;
