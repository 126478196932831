import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";

import Text from "../Text";
import {
  Align,
  AvailableTag,
  BreakpointWhiteSpace,
  Color,
  Whitespace,
} from "../Text.map";

import { subtitleSizeMap, TSubtitleSize } from "./TextSubtitle.map";

export interface ISubtitleProps extends WithDataAttr {
  id?: string;
  tag?: AvailableTag;
  size: TSubtitleSize
  align?: Align;
  color?: Color;
  children: ReactNode;
  className?: string;
  whitespace?: Whitespace | BreakpointWhiteSpace;
}

const TextSubtitle: FC<ISubtitleProps> = ({ id, size, align, color, tag = "h2", children, "data-testid": testId, className, whitespace = "default" }) => (
  <Text
    id={id}
    size={subtitleSizeMap[size]}
    display="subtitle"
    align={align}
    tag={tag}
    color={color}
    data-testid={testId}
    className={className}
    whitespace={ whitespace }
  >
    { children }
  </Text>
);

export default memo(TextSubtitle);
